import { Component, HostListener } from "@angular/core";
import { FormatDataPipe } from "../pipes/format-data.pipe";
import { FormatDatePipe } from "../pipes/format-date.pipe";
import { DomSanitizer } from "@angular/platform-browser";
import { SharedDataService } from "../services/shared-data.service";
import { DatePipe } from "@angular/common";
import notify from 'devextreme/ui/notify';
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { Device } from '@ionic-native/device/ngx';
import hiBase64 from 'hi-base64';
import { EventsService } from "../services/events-service";
import { Pro } from '@ionic/pro';
import { custom } from 'devextreme/ui/dialog';
import {v4 as uuid} from "uuid"; 
import { RestProviderService } from '../services/rest-provider.service';
import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { Platform } from "@ionic/angular";
import { FilePath } from "@ionic-native/file-path/ngx";
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { DBProviderService } from "../services/db-provider.service";

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})
export class ParentComponent {

  fdp: FormatDataPipe = new FormatDataPipe(this.sharedData, this.domSanitizer);
  fdtp: FormatDatePipe = new FormatDatePipe();
  loadingVisible: boolean = false;

  feedWidth: number = 250;
  headerTitleHeight: number = 18;
  headerHeight: number = 52;
  subHeaderHeight: number = 133;
  columnViewHeaderHeight: number = 50;
  toolbarHeight: number = 38;
  toolbarHeightMobile: number = 60;
  toolbarHeightMobilePopUp: number = 56;
  cardDetailTitleHeight = 86;
  footerHeight: number = 45;
  columnHeight: number = 41;
  footerHeightMobile: number = 57;
  maxPopupWidth: number = window.innerWidth;
  separatorWidth: number = 0;
  maxMobileWidth: number = 1024;
  scrollHeight: number = 6;
  scrollWidth: number = 6;
  titlePopUpHeight: number = 29;
  titlePopUpHeightMobile:number = 32;
  titlePopUpHeigthIphoneXPortraitOrientation = 62;
  addFooterHeightIPhoneXPortraitOrientation: number = 23;
  filterListToolbarMobilepHeight:number=100;
  filterListToolbarpHeight:number=35;
  addHeightPluginsMobile = 3;
  addHeighToolbarMobile = 8;
  subColumnHeaderHeigth:number=35;
  footerModalHeight:number=35;
  borderPoUps:number=8;
  minWidthCardListModalPopUp:number=450;
  minHeightCardListModalPopUp:number=300;
  appInternalVersion: number = 107.2;
  testIphoneX: boolean = false;
  showUploadingMessage:boolean=false;
  channel: string;
  allowedExtensionsOnlyOffice: Array<any> = ["DOC", "DOCX", "ODT", "RTF", "TXT", "PDF", "HTML", "EPUB", "XPS", "DJVU", "PPTX", "PPT", "ODP", "XLS", "XLSX", "ODS", "CSV"];
  lastBuild=7141914;
  mobileApp:string='bw2App';
  GUIDOverviewPlugin:string="40058332-1eac-43c5-a806-34e9c6efc21a";
  GUIDWikisPlugin:string="2f869c1d-7906-4dca-b8b4-e5fcdb68ecdb";
  objectInstanceError:string="ERROR - ERROR_CREATING_OBJECT_INSTANCE";
  numLinesTitleCard:number=2;
  numLinesTitleCardDocument:number=1;
  numLinesTitleCardTreeMode:number=1;
  numLinesTextPreviewCard:number=2;
  paddingLeftFirstColumn=12;
  paddingLeftOrRightColumn=5;
  paddingLeftOrRightColumnMobile=0;
  borderCard=3;
  paddingLeftOrRightCard=8;
  paddingLeftOrRightCellCard=3;
  itemImageCardWidth=20;
  documentImageMarginLeft=8;
  documentImageMarginRigth=20;
  documentImageWidth=80;
  iconDocumentWidth=15;
  
  constructor(
    public restProvider:RestProviderService,
    public events: EventsService,
    public domSanitizer: DomSanitizer,
    public sharedData: SharedDataService,
    public device: Device,
    public screenOrientation: ScreenOrientation) {
  }

  public hideAllContextMenu(){
    this.sharedData.currentContextMenu={};
    if(this.sharedData.contextMenu && this.sharedData.contextMenu.instance)
    {
      this.sharedData.contextMenu.instance.hide();
    }
  }
  
  @HostListener('window:orientationchange ', ['$event'])
  public onOrientationChange(event): void {
   this.hideAllContextMenu();
  }

  public showMessageConfigureBiometricAuth(ons:OpenNativeSettings,message:string){
    let html:string='<div style="display:flex;align-items:center">'+message+'<div>';
    let options = {
      title: this.fdp.transform(this.sharedData.captionKeyPrefix+'Message', 'GetTranslationCaption'),
      messageHtml: html,
      buttons: [
        {
          text: this.fdp.transform(this.sharedData.captionKeyPrefix+'Setup', 'GetTranslationCaption'),
          onClick: (() => 'setup')
        }, {
          text: this.fdp.transform('cancel', 'GetTranslationCaption'),
          onClick: (() => 'cancel')
        }
      ]
    }
    let dialog = custom(options);
    dialog.show().done(res => {
      switch (res) {
        case 'setup':this.openNativeSettings(ons);
          break;
        case 'cancel':
         break;
        default:
          break;
      }
    });
  }

  public openNativeSettings(ons:OpenNativeSettings){
    let setting:string="settings"
    this.executeOpenNativeSettings(setting,ons);
  }

  private executeOpenNativeSettings(setting:any,ons:OpenNativeSettings){
    if(setting!='')
    {
      ons.open(setting).then(val =>{
        this.sharedData.openedNativeSettings=true;
      }).catch(error=>{
        console.log(error);
      });
    }
  }

  public verifyIfAvailableBiometricAuthentication(faio: FingerprintAIO): Promise<any>{
    return new Promise((resolve, reject) => {
      faio.isAvailable().then((result: any) => {
        resolve(result);
      })
      .catch((error: any) => {
        reject(error);
      });
    });
  }

  public getPictureFromCamera(options:CameraOptions,camera:Camera,platform:Platform,filePath:FilePath): Promise<any>{
    return new Promise((resolve, reject) => {
      this.sharedData.disableOnResumeSubscription=true;
      camera.getPicture(options).then((imageData) => {
        if (platform.is('ios') ) {
          resolve(imageData);
          setTimeout(() => {
            this.sharedData.disableOnResumeSubscription=false;
          }, 500);
        }else if (platform.is('android')){
            filePath.resolveNativePath(imageData)
          .then(filePath => {
            resolve(filePath);
            setTimeout(() => {
              this.sharedData.disableOnResumeSubscription=false;
            }, 500);
          })
          .catch(err => {
            reject(err);
            setTimeout(() => {
              this.sharedData.disableOnResumeSubscription=false;
            }, 500);
          });
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  public manageBiometricAccessError(faio:FingerprintAIO,platform:Platform,error:any,parentPage:any,lastSetting?:any):any{
    console.error(error.code + ":" + error.message);
    this.sharedData.currentBiometricError={};
    this.sharedData.currentBiometricError.parentPage=parentPage;
    if(error.code == faio.BIOMETRIC_DISMISSED || error.code == faio.BIOMETRIC_UNKNOWN_ERROR)
    {
      this.sharedData.showBiometricMessageScreen=true;
      if(lastSetting)
      {
        this.sharedData.currentBiometricError.lastSetting=lastSetting;
      }
      this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricDismissedMessage", 'GetTranslationCaption');
      this.sharedData.currentBiometricError.showTryAgainButton=true;
      this.showHiddenMessage();
    }else if(error.code == faio.BIOMETRIC_LOCKED_OUT || error.code == faio.BIOMETRIC_LOCKED_OUT_PERMANENT)
    {
      this.sharedData.showBiometricMessageScreen=true;
      if(lastSetting)
      {
        this.sharedData.currentBiometricError.lastSetting=lastSetting;
      }
      if(platform.is('android'))
      {
        this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricLockedOutMessage", 'GetTranslationCaption');
        this.showHiddenMessage();
        setTimeout(() => {
          this.sharedData.currentBiometricError.showTryAgainButton=true;
          this.showHiddenMessage();
        }, 30000);
      }else{
        this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricLockedOutMessageIOS", 'GetTranslationCaption');
        this.sharedData.currentBiometricError.showEnterCodeButton=true;
        this.showHiddenMessage();
      }
    }else if (error.code == faio.BIOMETRIC_AUTHENTICATION_FAILED || error.code == faio.BIOMETRIC_SCREEN_GUARD_UNSECURED) {
      this.sharedData.showBiometricMessageScreen=true;
      if(lastSetting)
      {
        this.sharedData.currentBiometricError.lastSetting=lastSetting;
      }
      this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricAuthenticationFailedMessage", 'GetTranslationCaption');
      this.sharedData.currentBiometricError.showTryAgainButton=true;
      this.showHiddenMessage();
    }else{
      this.sharedData.showBiometricMessageScreen=true;
      if(lastSetting)
      {
        this.sharedData.currentBiometricError.lastSetting=lastSetting;
      }
      this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricPluginErrorMessage", 'GetTranslationCaption')+"_"+error.code + ":" + error.message;
      this.sharedData.currentBiometricError.showTryAgainButton=true;
      this.showHiddenMessage();
    }
  }


  public verifyIfEnterApp(dbProvider:DBProviderService,faio: FingerprintAIO,platform:Platform): Promise<any>{
    return new Promise((resolve, reject) => {
      if (this.sharedData.biometricAccessEnabled) {
        this.manageVerifyAvailableBiometricAuthentication(dbProvider,faio,platform).then(res => {
          resolve(true);
        }, error => {
          reject(error);
        });
      }else{
        this.sharedData.currentBiometricError={};
        this.sharedData.showBiometricMessageScreen=false;
        this.sharedData.biometricAccessEnabled = false;   
        this.showHiddenMessage();     
        dbProvider.updateAuthenticationType("password");
        resolve(true);
      }
    });
  }

  public executeBiometricAuthentication(faio:FingerprintAIO,platform:Platform) : Promise<any>{
    return new Promise((resolve, reject) => {
      this.sharedData.currentBiometricError={};
      this.showHiddenMessage();
      this.validateBiometricAuthentication(faio,platform).then(res => {
        this.sharedData.showBiometricMessageScreen=false;
        this.showHiddenMessage();
        resolve(true);
      }, error => {
        this.manageBiometricAccessError(faio,platform,error,this);
        reject(error);
      });
    });
  }

  private manageVerifyAvailableBiometricAuthentication(dbProvider:DBProviderService,faio:FingerprintAIO,platform:Platform,notRecallMethod?:boolean): Promise<any>{
    return new Promise((resolve, reject) => {
      this.sharedData.currentBiometricError={};
      this.sharedData.showBiometricMessageScreen=true;
      this.showHiddenMessage();
      this.verifyIfAvailableBiometricAuthentication(faio).then(res => {
        if(notRecallMethod)
        {
          this.executeBiometricAuthentication(faio,platform).then(res => {
            resolve(true);
          }, error => {
            reject(error);
          });
        }else{
          setTimeout(() => {
            this.manageVerifyAvailableBiometricAuthentication(dbProvider,faio,platform,true).then(res => {
              resolve(true);
            }, error => {
              reject(error);
            });
          }, 500);
        }
      }, error => {        
        console.error(error.code + ":" + error.message);
        if(platform.is('ios') && error.code == faio.BIOMETRIC_UNAVAILABLE && error.message == this.sharedData.biometryDeniedByUserMessage){
          this.executeBiometricAuthentication(faio,platform).then(res => {
            resolve(true);
          }, error => {
            reject(error);
          });
        }else if(platform.is('ios') && error.code == faio.BIOMETRIC_UNKNOWN_ERROR && 
        (error.message==this.sharedData.biometryLockedOutMessage || error.message==this.sharedData.biometryPasscodeLockedOutMessage))
        {
          this.sharedData.currentBiometricError.parentPage=this;
          this.sharedData.showBiometricMessageScreen=true;
          this.sharedData.currentBiometricError.message=this.fdp.transform(this.sharedData.captionKeyPrefix+"BiometricLockedOutMessageIOS", 'GetTranslationCaption');
          this.sharedData.currentBiometricError.showEnterCodeButton=true;
          this.showHiddenMessage();
          reject(error);
        }else{
          this.sharedData.showBiometricMessageScreen=false;
          this.showHiddenMessage();
          this.sharedData.biometricAccessEnabled = false;        
          dbProvider.updateAuthenticationType("password");
          resolve(true);
        }
      });
    });
  }


  public validateBiometricAuthentication(faio: FingerprintAIO,platform:Platform): Promise<any>{
    let title=this.fdp.transform(this.sharedData.captionKeyPrefix+'BiometricAccess', 'GetTranslationCaption');;
    let options={};
    if(platform.is('ios'))
    {
        options={
          title: title,
          cancelButtonTitle:this.fdp.transform('Cancel', 'GetTranslationCaption'),
        }
    }else{
      options={
        title: title,
        disableBackup:true,
        cancelButtonTitle:this.fdp.transform('Cancel', 'GetTranslationCaption')
      }
    }
    return new Promise((resolve, reject) => {
        this.sharedData.disableOnResumeSubscription=true;
        faio.show(options)
        .then((result: any) => {
          setTimeout(() => {
            this.sharedData.disableOnResumeSubscription=false;
            resolve(true);
          }, 250);
        })
        .catch((error: any) => {
          setTimeout(() => {
            this.sharedData.disableOnResumeSubscription=false;
            reject(error);
          }, 250);
        });
    });
  }

  public executeGetSessionKeyDevice(setting:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedData.deviceGuid = setting['device_guid'];
      this.sharedData.portalGuid = setting['portal_guid'];
      this.restProvider.executeHttpMethod('GetSessionKeyDevice', {})
        .subscribe(result => {
          let keys = JSON.parse(result);
          this.sharedData.sessionKey = keys.sessionKey;
          if (!keys.applicationUrl.endsWith('/'))
            keys.applicationUrl = keys.applicationUrl + '/';
          this.sharedData.appURL = keys.applicationUrl + 'GetContent.aspx';
          this.setResources();
          resolve(true);
        }, error => {
          console.error(error);
          this.showMessage('Error', JSON.stringify(error));
          reject(error);
        });
    });
  }

  public resetCurrentPortal():void{
    if(this.sharedData.currentPortal)
    {
      this.sharedData.currentPortalTmp=this.sharedData.currentPortal;
      this.sharedData.deviceGuid = this.sharedData.currentPortal['device_guid'];
      this.sharedData.portalGuid = this.sharedData.currentPortal['portal_guid'];
      this.sharedData.sessionKey=this.sharedData.currentPortal['session_key'];
      this.sharedData.appURL=this.sharedData.currentPortal['app_URL'];
      this.setResources();
    }
  }

  public getWidthTextPreview(card:any){
    let width:number=0;
    if(card.DocumentGUID!=undefined)
    {
        width=card.width-this.borderCard - this.documentImageMarginLeft - this.documentImageMarginRigth -
         this.documentImageWidth - 2 * this.paddingLeftOrRightCard;
    }else{
      width=card.width-this.borderCard-2*this.paddingLeftOrRightCard
    }
    return width;
  }

  public getWidthTitleCard(card:any){
    let width:number=0;
    if(card.DocumentGUID!=undefined)
    {
        width=card.width-this.borderCard - this.documentImageMarginLeft - this.documentImageMarginRigth -
         this.documentImageWidth - 2 * this.paddingLeftOrRightCard - this.iconDocumentWidth;
    }else{
      if(card.TitleRight!=undefined && card.TitleRight!='')
      {
        width=(card.width-this.borderCard- 2 * this.paddingLeftOrRightCard)* 0.8;
      }else{
        width=(card.width-this.borderCard- 2 * this.paddingLeftOrRightCard);
      }
      if(card.Icon!=undefined)
      {
        width=width-this.itemImageCardWidth;
      }
    }
    return width;
  }

  public measureText(string:string, fontSize:number) {
    const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.1140625,0.3,0.4,0.7,0.6,0.9,0.9,0.23125,0.4,0.403125,0.5,0.7,0.3171875,0.4,0.2171875,0.6,0.6,0.5390625,0.6,0.5390625,0.7,0.6,0.6,0.6,0.6,0.6,0.2171875,0.3171875,0.684375,0.7,0.684375,0.5,1,0.8,0.6,0.6203125,0.7015625,0.50625,0.5,0.7,0.7109375,0.2671875,0.4578125,0.7,0.5,0.9,0.7484375,0.8,0.6,0.8,0.7,0.6,0.7,0.7,0.8,1.1,0.8,0.7,0.7,0.4,0.6,0.303125,0.7,0.6,0.3,0.509375,0.6,0.5,0.6,0.6,0.4,0.6,0.6,0.2421875,0.4421875,0.6,0.2421875,0.9,0.6,0.6,0.6,0.6,0.4,0.5,0.5,0.6,0.7,0.9,0.6,0.7,0.6,0.4,0.240625,0.303125,0.7]
    const avg = 0.5764144736842108
    return string
      .split('')
      .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
      .reduce((cur, acc) => acc + cur) * fontSize
  }
    
  public getDataBrowseFromParentFilter(parentFilter:any,parentPage:any):void{
    let popupHeight=0;
    if (this.isMobileMode()) {
      popupHeight = window.innerHeight;
    } else {
      popupHeight = window.innerHeight * 0.95;
    }
    let preference=this.getUserPreferencesBrowse(parentFilter['GridFilter_BusinessObject_GUID']);
    let params:any = {
      itemGuid: parentFilter['GridFilter_ListboardBrowse_GUID'],
      browseFilterId:parentFilter['GridFilter_ID'],
      selectedView:"DefaultListView",
      groupBy:"",
      orderBy:"",
      controlWidth:this.getControlWidthModalPopUp()
    };
    let queryTop=this.sharedData.queryTop;
    if(preference)
    {   
      if(preference['view_key']=='TableView_Medium')
      {
        preference['view_key']=this.sharedData.DefaultTableView;
      }   
      if (preference['filters'])
      {
        let listFilters=[]
        try {
          let list =preference['filters'];
          if(list && list.length>0)
          {
            list.forEach(element => {
              let jsonElement=JSON.parse(element);
              listFilters.push(jsonElement);
            });   
          }
        } catch (error) {
          console.error(error);
        }
        listFilters=this.getListFiltersDecodeBase64(listFilters);
        params.filters=this.getParamFilters(listFilters);
      }
      if(preference['group_id'])
      {
        params.groupBy=preference['group_id'];
      }
      if(preference['order_id'])
      {
        params.orderBy=preference['order_id'];
      }else{
        if(parentFilter['GridFilter_ListboardBrowse_GUID'] == '47c11407-a9ee-4e5a-8efe-75e971f0fe11') { //dimension
          params.orderBy='Dimension_ID';
        }
      }
      if(preference['view_key'])
      {
        params.selectedView=preference['view_key'];
      }
      if(preference['top'])
      {
        queryTop=preference['top'];
      }
    }
    this.restProvider.executeMethod("BW2Mobile_GetDataJson", params).then(
      result => {
        try {
          let data = JSON.parse(result);
          let moreItems = false;
          let listSelectedFiltersBrowse=data['ListFilters'];
          listSelectedFiltersBrowse=this.getListFiltersDecodeBase64(listSelectedFiltersBrowse);
          if (data['Cards'].length > queryTop) {
            data['Cards'] = data['Cards'].slice(0,queryTop);
            moreItems = true;
          }
          let tableView:boolean=params.selectedView==this.sharedData.DefaultTableView?true:false;
          this.sharedData.browseParams={
            browseGuid:parentFilter['GridFilter_BusinessObject_GUID'],
            subColumns:data['SubColumns'],
            queryTop:queryTop,
            selectedView:params.selectedView,
            groupBy:params.groupBy,
            groupByDirection : preference['group_direction']?preference['group_direction']:'ASC',
            orderBy:params.orderBy,
            orderByDirection :  preference['order_direction']?preference['order_direction']:'ASC',
            listSelectedFilters:listSelectedFiltersBrowse,
            listCards:data['Cards'],
            listColumns:data['Columns'],
            moreItems:moreItems,
            tableView:tableView,
            modalParams:params,
            popupHeight:popupHeight,
            itemGuid:parentFilter['GridFilter_ListboardBrowse_GUID']
          }
          let popupdata={
            browseParams:this.sharedData.browseParams,
            titlePopUp:parentFilter['GridFilter_Caption'],
            parentPage:parentPage,
            parentFilter:parentFilter
          }
          this.addCardListModalPopUp(popupdata);
        } catch (error) {
          console.error(error);
          this.showMessage('Error', error);
        }
      },
      error => {
        console.error(error);
        this.showMessage('Error', error);
      });
}

  public getTextLinesFromtext(font:any,text:string,widthText:number,maxNumLines:number){
    try{
      let widthAllText=this.measureText(text,font); 
      let numCharactersPerLine:number=Math.round(widthText * text.length / widthAllText)-3;
      let truncated=false;
      let lineText:string="";
      let words=text.split(" ");
      let textLines=[];
      let numWords=words.length;
   
      if (numWords > 0) {
        for (let i = 0; i < numWords; i++) {
          if (lineText == "") {
            lineText = words[i];
            if (i == words.length - 1) {
              if (lineText.length > numCharactersPerLine) {
                lineText = lineText.substr(0, numCharactersPerLine - 6) + "...";
                truncated = true;
              }
              textLines.push(lineText);
            }
          } else {
            let lineTextTmp = lineText + " " + words[i];
            if (lineTextTmp.length > numCharactersPerLine) {
              if (textLines.length == maxNumLines - 1) {
                lineText = lineTextTmp.substr(0, numCharactersPerLine - 6);
                textLines.push(lineText + "...");
                truncated = true;
                break;
              } else {
                textLines.push(lineText);
                lineText = words[i];
                if (i == words.length - 1) {
                  textLines.push(lineText);
                }
              }
            } else {
              lineText = lineText + " " + words[i];
              if (i == words.length - 1) {
                textLines.push(lineText);
              }
            }
          }
        }
      }
      
      let result={
        textLines:textLines,
        truncated:truncated
      }
      return result;
    }catch(e){
      console.log(e);
      return undefined;
    }
  }

  public getControlWidthModalPopUp(){
    let controlWidth=0;
    if(!this.isMobileMode())
    {
      controlWidth=window.innerWidth * 0.8 > this.sharedData.maxWidthPopup ? this.sharedData.maxWidthPopup : window.innerWidth * 0.8;
      controlWidth-controlWidth-this.borderPoUps;
    }else{
      controlWidth=window.innerWidth;
    }
    return Math.round(controlWidth);
  }

  public getTitlePopUpHeigth(){
    if(this.isIPhoneXPortraitOrientation())
    {
      return this.titlePopUpHeigthIphoneXPortraitOrientation;
    }else{
      if(this.isMobileMode())
      {
        return this.titlePopUpHeightMobile;
      }else{
        return this.titlePopUpHeight;
      }
    }
  }

  public getParentFilter(filter){
    let parentFilter:any={
      GridFilter_BusinessObject_GUID:filter['GridFilter_BusinessObject_GUID'],
      GridFilter_ListboardBrowse_GUID: filter['GridFilter_ListboardBrowse_GUID'],
      GridFilter_ID:filter['GridFilter_ID'],
      GridFilter_Caption:filter['GridFilter_Caption'],
      GridFilter_Type:filter['GridFilter_Type'],
      GridFilter_DataType:filter['GridFilter_DataType'],
      GridFilter_ORKey:filter['GridFilter_ORKey'],
      GridFilter_SortKey:filter['GridFilter_SortKey']
    }
    return parentFilter;
  }

  public hideTooltip(event?:any){
    if(event)
      event.target.id="";
      this.sharedData.currentTooltip={};
  }

  public showTooltipFilter(event:any,filter:any){
      let inputText:string="";
      if(filter['parentFilter'])
      {
        if(filter['GridFilter_Type']==2)
        {
          inputText=filter['parentFilter']['GridFilter_Caption']+":"+filter['GridFilter_Caption'];
        }
        if(filter['GridFilter_Type']==4)
        {
          if(filter['showPrefixCaption'])
          {
            if(filter['GridFilter_PrefixCaption'] && filter['GridFilter_PrefixCaption']!='')
            {
              inputText=filter['GridFilter_PrefixCaption']+" : "+filter['GridFilter_Caption'];
            }
            if(!filter['GridFilter_PrefixCaption'] || (filter['GridFilter_PrefixCaption'] && filter['GridFilter_PrefixCaption']==''))
            {
              inputText=filter['GridFilter_Caption']
            }
          }else{
            inputText=filter['GridFilter_Caption']
          }
        }
      }else{
        inputText=filter['GridFilter_Caption']
      }
      this.showToolTip(event,inputText);
  }

  public showTooltipSelectedToolbarOptions(event:any,selectedToolbarOptionList:any){
    if(selectedToolbarOptionList && selectedToolbarOptionList.length>0)
    {
      let stringHtml="";
      selectedToolbarOptionList.forEach(detail => {
        if(detail && detail.ActionType)
        {
          let actionType:string="";
          if(detail.ActionType=="View")
          {
            actionType=this.fdp.transform('columnsBy', 'GetTranslationCaption');
          }
          if(detail.ActionType=="GroupBy")
          {
            actionType=this.fdp.transform('groupBy', 'GetTranslationCaption');
          }
          if(detail.ActionType=="OrderBy")
          {
            actionType=this.fdp.transform('sortBy', 'GetTranslationCaption');
          }
          stringHtml=stringHtml+"<div><span>"+actionType+" : "+detail.Description+"</span><br></div>";
        }
      });
      this.showToolTip(event,stringHtml,true);
    }
  }

  public showToolTipCard(event:any,card:any) {
    if(card && card.titleTruncated){
      this.showToolTip(event,card.Title);
    }
  }

  public showToolTipCellCard(event:any,card:any) {
    if(!this.isMobile())
    {
      if(card.truncated){  
        let text="";
        if(card.Title!=undefined && card.Title!='')
        {
          text=card.Title;
        }else if(card.TitleRight!=undefined && card.TitleRight!='')
        {
          text=card.TitleRight;
        }
        this.showToolTip(event,text);
      }
    } 
  }

  public showToolTip(event:any,input:any,fromCustomHtml?:boolean) {
      if(event && !this.isMobile() && input && input != ''){
        this.sharedData.currentTooltip={};
          if(!event.target.id){
            let generatedId:string="id"+Math.round(Math.random() * 10000000);
            event.target.id=generatedId;
          }
          const rect =event.target.getBoundingClientRect();
          this.sharedData.currentTooltip.elementId=event.target.id;
          let x = event.x- rect.left;
          let y = event.y - rect.top + 20;
          this.sharedData.currentTooltip.offset=x+" "+y;
        if(fromCustomHtml)
        {
          this.sharedData.currentTooltip.innerHtml=input;
        }else{
          input=this.fdp.transform(input, 'GetTranslationCaption');
          this.sharedData.currentTooltip.innerHtml=this.getHtmlTooltip(input);
        }
        this.sharedData.currentTooltip.showTooltip=true;  
      }
  }

  public getHtmlTooltip(input:any){
    return "<div style='max-width:900px;white-space: pre-line;'><span>"+input+"</span></div>";
  }

  public orderFilterInToolbar(listSelectedFilters:any):any{
    if(listSelectedFilters && listSelectedFilters.length>0)
    {
      listSelectedFilters.sort(this.sort_by('GridFilter_ID', false, parseInt));
      listSelectedFilters.sort(this.sort_by('GridFilter_SortKey', false, parseInt));
      listSelectedFilters.sort(function(a,b){
        if(a['GridFilter_ORKey']!="" && b['GridFilter_ORKey']=="")
        {
            return 1;
        }else{
          if(a['GridFilter_ORKey']=="" && b['GridFilter_ORKey']!="")
          {
            return -1;
          }else{
            return 0;
          }
        }
      });
    }
    return listSelectedFilters
  }

  public enableAddButtonBrowse(filter:any,listSelectedFilters){
    let result:boolean=false;
    let listFilters=listSelectedFilters.filter(item => item['GridFilter_ID']==filter['GridFilter_ID']);
    if(listFilters && listFilters.length>0)
    {
      if(listFilters.indexOf(filter)==listFilters.length-1)
      {
        result=true;
      }
    }
    return result;
  }

  public enableAndFilter(filter:any,listSelectedFilters:any):boolean{
      let result:boolean=false;
      if(filter['GridFilter_ORKey']!='')
      {
        let listFilters=listSelectedFilters.filter(item => item['GridFilter_ORKey']==filter['GridFilter_ORKey']);
        if(listFilters && listFilters.length>0)
        {
          if(listFilters.indexOf(filter)==listFilters.length-1)
          {
            result=true;
          }
        }
      }else{
        if(filter['GridFilter_Type']==2 || filter['GridFilter_Type']==4)
        {
          let listFilters=listSelectedFilters.filter(item => item['GridFilter_ID']==filter['GridFilter_ID']);
          if(listFilters && listFilters.length>0)
          {
            if(listFilters.indexOf(filter)==listFilters.length-1)
            {
              result=true;
            }
          }
        }else{
          result=true;
        }
      }
      return result;
  }

  public addEditItemPopUp(popupdata:any){
    this.hideTooltip();
    popupdata.popUpId="popUpId-"+uuid();
    this.sharedData.listEditItemPopUp.push(popupdata);
  }

  public addCardListModalPopUp(popupdata:any){
    this.hideTooltip();
    popupdata.popUpId="popUpId-"+uuid();
    this.sharedData.listCardListModalPopUp.push(popupdata);
  }

  public addFilterListPopUp(popupdata:any){
    this.hideTooltip();
    popupdata.popUpId="popUpId-"+uuid();
    this.sharedData.listFilterListPopUp.push(popupdata);
  }

  public getCardListModalPopUpByParentPageAndPopUpId(popupParent:any){
    let cardListModalPopUp=this.sharedData.listCardListModalPopUp.find(item => item.popupParent == popupParent);
    return cardListModalPopUp;
  }

  public updateCardDetailPopUp(cardData:any,enableCardOptions?:boolean){
    let item=this.sharedData.listCardDetailPopUp.find(item => item['card'].ID == cardData['card'].ID
      && item['globalCount']== cardData['globalCount']);
     if(item && item.cardDetailPage)
     {
      item.cardDetailPage.updateCardData(cardData);
      if(item.cardDetailPage.cardDetailToolbarPage && enableCardOptions)
      {
        item.cardDetailPage.cardDetailToolbarPage.getActions();
        item.cardDetailPage.cardDetailToolbarPage.enableCardOptions=true;
      }
     } 
  }
  
  public addOrUpdateCardDetailPopUp(cardData:any,enableCardOptions?:boolean){
    this.hideTooltip();
    if(cardData)
    {
      let item=this.sharedData.listCardDetailPopUp.find(item => item['card'].ID == cardData['card'].ID
      && item['globalCount']== cardData['globalCount']);
     if(item==undefined)
     {
      cardData.popUpId="popUpId-"+uuid();
       this.sharedData.listCardDetailPopUp.push(cardData);
     }else if(item.cardDetailPage){
      item.cardDetailPage.updateCardData(cardData);
      if(item.cardDetailPage.cardDetailToolbarPage && enableCardOptions)
      {
        item.cardDetailPage.cardDetailToolbarPage.getActions();
        item.cardDetailPage.cardDetailToolbarPage.enableCardOptions=true;
      }
     } 
    }
  }

  public getUserPreferencesBrowse(browseGuid: string):any {
    let dataUserPreference={};
    let userSetting:any=undefined;
    if(this.sharedData.userSettings.length>0)
    {
      userSetting=this.sharedData.userSettings.find(us => us['browse_Guid'] == browseGuid);
    }
    if(userSetting && userSetting['data'])
    {
      dataUserPreference=JSON.parse(userSetting['data']);
    }
    return dataUserPreference;
  }

  public getUserPreferences(viewId: number, pluginId?: number):any {
    let dataUserPreference={};
    let userSetting:any=undefined;
    if(this.sharedData.userSettings.length>0)
    {
      if(viewId && pluginId)
      {
        userSetting=this.sharedData.userSettings.find(us => us['view_id'] == viewId &&  us['plugin_id'] == pluginId);
      }else{
        if(viewId)
        {
          userSetting=this.sharedData.userSettings.find(us => us['view_id'] == viewId);
        }
      }
    }
    if(userSetting && userSetting['data'])
    {
      dataUserPreference=JSON.parse(userSetting['data']);
    }
    return dataUserPreference;
  }

  public showNotDeletePermissionMessage(plugin:any){
    let html:string='<div style="display:flex;align-items:center"><img style="width: 35px;margin-right: 10px;" src="assets/icon/nu-circle-remove.png">'+this.fdp.transform('No Delete Permission', 'GetTranslationCaption')+'<div>';
    let options = {
      title: plugin.CurrentCaption,
      messageHtml: html,
      buttons: [
        {
          text: 'Ok',
          onClick: (() => 'ok')
        }
      ]
    }
    let dialog = custom(options);
    dialog.show().done(res => {
      switch (res) {
        case 'ok':
          break;
        default:
          break;
      }
    });
  }

  public verifyAllowDeleteCard(card:any,listObjects:any[]):boolean{
    let enableDeleteCard:boolean=false;
    if(listObjects && listObjects.length>0)
    {
      let listObjectsForDelete=listObjects.filter(obj => obj.DeletePermission==true);
      let index=listObjectsForDelete.findIndex(obj => obj.GUID==card.BOGUID);
      if(index!=-1)
        enableDeleteCard=true;
    }
    return enableDeleteCard;
  }

  public verifyAllowDeleteListCard(cardList:any,listObjects:any[]){
    let allowedCardsListDelete: any[] = [];
    let notAllowedCardListDelete:any[]=[];
    if(cardList && cardList.length>0)
    {
      cardList.forEach(card => {
        if (this.verifyAllowDeleteCard(card, listObjects)) {
          allowedCardsListDelete.push(card);
        }else{
          notAllowedCardListDelete.push(card);
        }
      });
    }
    let result = {
      notAllowedCardListDelete:notAllowedCardListDelete,
      allowedCardsListDelete:allowedCardsListDelete
    }
    return result;
  }

  public verifyAllowEditCard(card:any,listObjects:any[]):boolean{
    let enableEditCard:boolean=false;
    if(listObjects && listObjects.length>0)
    {
      let listObjectsForRead=listObjects.filter(obj => obj.ReadPermission==true);
      let index=listObjectsForRead.findIndex(obj => obj.GUID==card.BOGUID);
      if(index!=-1)
        enableEditCard=true;
    }
    return enableEditCard;
  }


  public getListObjectsToCreate(listObjects:any[]):any[]{
    let listObjectsToCreate:any[]=[];
    if(listObjects)
    {
      listObjectsToCreate=listObjects.filter(obj => obj.CreatePermission==true);
    }
    return listObjectsToCreate;
  }

  async configureDeploy() {
    let updateMethod: any = 'none';
    let appId = '51a74936';
    this.channel = 'Production';
    if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.0.1.8')==0) {//DEV
      const config = {
        appId: appId,
        channel: this.channel,
        updateMethod: updateMethod
      }
      await Pro.deploy.configure(config);
    } else {
      if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.1.0.47') <= 0) {
        this.channel = 'Channel51';
        const config = {
          appId: appId,
          channel: this.channel,
          updateMethod: updateMethod
        }
        await Pro.deploy.configure(config);
      } else {
        if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.1.0.66') <= 0) {
          this.channel = 'Channel66';
          const config = {
            appId: appId,
            channel: this.channel,
            updateMethod: updateMethod
          }
          await Pro.deploy.configure(config);
        } else {
          if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.1.0.72') <= 0) {// PREV VERSION
            this.channel = 'Channel70';
            const config = {
              appId: appId,
              channel: this.channel,
              updateMethod: updateMethod
            }
            await Pro.deploy.configure(config);
          } else {
            if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.11') <= 0) {// PREV VERSION
              this.channel = 'Channel90';
              const config = {
                appId: appId,
                channel: this.channel,
                updateMethod: updateMethod
              }
              await Pro.deploy.configure(config);
            } else {
              if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.19') <= 0) {// PREV VERSION
                this.channel = 'Channel93';
                const config = {
                  appId: appId,
                  channel: this.channel,
                  updateMethod: updateMethod
                }
                await Pro.deploy.configure(config);
              } else {
                if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.44') <= 0) {// PREV VERSION
                  this.channel = 'Channel98';
                  const config = {
                    appId: appId,
                    channel: this.channel,
                    updateMethod: updateMethod
                  }
                  await Pro.deploy.configure(config);
                } else {
                  if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.51') <= 0) {// PREV VERSION
                    this.channel = 'Channel99';
                    const config = {
                      appId: appId,
                      channel: this.channel,
                      updateMethod: updateMethod
                    }
                    await Pro.deploy.configure(config);
                  } else {
                    if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.62') <= 0) {// PREV VERSION
                      this.channel = 'Channel100';
                      const config = {
                        appId: appId,
                        channel: this.channel,
                        updateMethod: updateMethod
                      }
                      await Pro.deploy.configure(config);
                    } else {
                      if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.82') <= 0) {// PREV VERSION
                        this.channel = 'Channel103';
                        const config = {
                          appId: appId,
                          channel: this.channel,
                          updateMethod: updateMethod
                        }
                        await Pro.deploy.configure(config);
                      } else {
                        if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.98') <= 0) {// PREV VERSION
                          this.channel = 'Channel106';
                          const config = {
                            appId: appId,
                            channel: this.channel,
                            updateMethod: updateMethod
                          }
                          await Pro.deploy.configure(config);
                        } else {
                          if (this.compareVersions(this.sharedData.bussinessSuiteVersion, '5.2.0.101') <= 0) {// PREV VERSION
                            this.channel = 'Channel107';
                            const config = {
                              appId: appId,
                              channel: this.channel,
                              updateMethod: updateMethod
                            }
                            await Pro.deploy.configure(config);
                          } else {
                            const config = {
                              appId: appId,
                              channel: this.channel,
                              updateMethod: updateMethod
                            }
                            await Pro.deploy.configure(config);
                          } 
                        } 
                      } 
                    } 
                  } 
                } 
              } 
            } 
          } 
        } 
      }
    }
    this.sharedData.channel=this.channel;
}

async checkAndDownloadUpdate(){
  const update = await Pro.deploy.checkForUpdate()
  if(update && update.available && update.build)
  {
    console.log("update:::::"+JSON.stringify(update));
    console.log("currentBuild:::"+update.build);
    if(this.sharedData.channel=='Production' || this.sharedData.channel=='Master')
    {
      let currentBuild=parseInt(update.build);
      if(currentBuild > this.lastBuild)
      {
        this.prepareAndDownloadUpdate();
      }
    }else{
      this.prepareAndDownloadUpdate();
    }
  }
}

private prepareAndDownloadUpdate(){
  this.sharedData.loadingText=this.fdp.transform('Loading', 'GetTranslationCaption');
        this.sharedData.pleaseWaitText=this.fdp.transform('PleaseWait', 'GetTranslationCaption');
        this.sharedData.updatingAppText=this.fdp.transform('UpdatingApp', 'GetTranslationCaption');
        setTimeout(() => {
          this.sharedData.displayUpdatingWindow=true;
          this.executeDownloadUpdate();
        }, 500);
}

async executeDownloadUpdate(){
  await Pro.deploy.downloadUpdate((progress) => {
      this.sharedData.progressDownloading=progress+"%";
      //this.showMessage('success',"DownloadingProgress:::"+progress+"%",2000000000)
    }).then(success=>{
    this.executeExtractUpdate();
  }).catch(error=>{ 
    this.showMessage('error',error);
    this.sharedData.displayUpdatingWindow=false;
    this.sharedData.progressDownloading='';
    console.error(error);
 });
}

async reloadApp(){
  await Pro.deploy.reloadApp();
}

async executeExtractUpdate(){
  this.showMessage('success',"executeExtractUpdate");
  await Pro.deploy.extractUpdate((progress) => {}).then(success=>{
    this.reloadApp();
  }).catch(error=>{ 
    this.showMessage('error',error);
    console.error(error);
    this.executeExtractUpdate();
 });
}




  public isMobileMode():boolean{
    if(window.innerWidth >= this.sharedData.menuWidth + this.sharedData.minWidthCardListColumn + this.sharedData.minWidthPreview)
    {
      return false;
    }
    return true;
  }

  public isChromeBrowser():boolean {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf('chrome') > -1 && !!(<any>window).chrome) {
      return true;
    } 
    return false;
  }

  public disableDeleteSelectedItem(globalCount:number,subPlugin:any,plugin:any){
    if(globalCount>0 && subPlugin && (subPlugin.GUID=='4b5eeca4-79b8-450d-aa90-0876454d984d') && plugin.GUID!='8c0cb8f4-4c7d-4a9f-b4f1-7a6178694f32')
    {
      return true;
    }
    return false;
  }

  public unblockAllScreen(){
    this.events.publish('HomePage:executeBlockOrUnblockScreenContent', {blockScreen:false});
    this.events.publish('MainPage:executeBlockOrUnblockScreenContent', {blockScreen:false});
  }

  public blockAllScreen(){
    this.events.publish('HomePage:executeBlockOrUnblockScreenContent', {blockScreen:true});
    this.events.publish('MainPage:executeBlockOrUnblockScreenContent', {blockScreen:true});
  }

  public executeBlockOrUnblockScreen(blockScreen:boolean,globalCount:number){
    let jsonParams:any={
      blockScreen:blockScreen,
      disableListboard:this.sharedData.disableListboard
    }
    if(globalCount==0)
    {
       this.events.publish('HomePage:executeBlockOrUnblockScreen',{jsonParams:jsonParams});
    }else{
      this.events.publish('CardDetailPage:executeBlockOrUnblockScreen'+globalCount,{jsonParams:jsonParams});
    }
  }

  public dragElement(popUpId, elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let titlePopUp=document.getElementById("title"+popUpId);
    if(titlePopUp)
      titlePopUp.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      let top = elmnt.offsetTop - pos2;
      if(top<0)
      {
        top=0;
      }
      let left=elmnt.offsetLeft - pos1;
      if(left<0)
      {
        left=0;
      }
      if(left+elmnt.offsetWidth<=window.innerWidth)
        elmnt.style.left = left + "px";
      if(top + elmnt.offsetHeight<=window.innerHeight)  
        elmnt.style.top = top + "px"; 
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  public isIPhoneXPortraitOrientation(): boolean {
    if (this.testIphoneX) {
      if (!this.screenOrientation.type.startsWith('landscape')) {
        return true;
      }
    } else {
      if (this.isMobile()) {
        if (this.device.model.includes('iPhone10,3') || this.device.model.includes('iPhone10,6') || this.device.model.includes('iPhone11') || this.device.model.includes('iPhone12')) {
          if (!this.screenOrientation.type.startsWith('landscape')) {
            return true;
          }
        }
      }
      return false;
    }
  }

  public getShowScrollbar() {
    if ((<any>window).cordova != undefined) {
      return 'never';
    } else {
      return 'always';
    }
  }

  public getStyleDisplayScrollView() {
        return 'block';
  }

  public isMobile(): boolean {
    return (<any>window).cordova != undefined;
  }

  public setResources(): void {

    let normalizeCss = document.createElement('link');
    normalizeCss.setAttribute('href', this.sharedData.appURL + '?DocumentKey=normalize-css&DocumentType=res');
    normalizeCss.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('head')[0].appendChild(normalizeCss);

    let previewCss = document.createElement('link');
    previewCss.setAttribute('href', this.sharedData.appURL + '?DocumentKey=preview-css&DocumentType=res');
    previewCss.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('head')[0].appendChild(previewCss);

    let highlightCss = document.createElement('link');
    highlightCss.setAttribute('href', this.sharedData.appURL + '?DocumentKey=highlight9_15_4_forcecache_min-css&DocumentType=res');
    highlightCss.setAttribute('rel', 'stylesheet');
    document.getElementsByTagName('head')[0].appendChild(highlightCss);

    let highlightJs = document.createElement('script');
    highlightJs.setAttribute('src', this.sharedData.appURL + '?DocumentKey=highlight9_15_4_forcecache_min-js&DocumentType=res');
    document.getElementsByTagName('head')[0].appendChild(highlightJs);
  }

  public orderPlugins(plugins: any[]): any[] {
    let groups = [];
    for (let i = 0; i < plugins.length; i++) {

      if (plugins[i]['PluginSortKey'].indexOf("!") != -1)
        plugins[i]['ShowPipe'] = true

      plugins[i]['PluginSortKey'] = plugins[i]['PluginSortKey'].replace('!', '');
      let plugin = plugins[i];

      if (plugin['CurrentCaption'] && plugin['CurrentCaption'].indexOf('|') != -1) {
        let caption = plugin['CurrentCaption'].split('|');
        plugin['CurrentCaption'] = caption[0];
        plugin['Tooltip'] = caption[1];
      }

      let parts = plugin['PluginSortKey'].split('-');
      if (parts[0] == 'Plugins') {
        plugin['Group'] = 9999;
      } else {
        plugin['Group'] = parts[0] / 1;
      }
      plugin['Order'] = parts[1] / 1;

      if (groups.indexOf(plugin['Group']) == -1) {
        groups.push(plugin['Group']);
      }
    }
    let result = [];
    for (let i = 0; i < groups.length; i++) {
      let tmp = [];
      for (let j = 0; j < plugins.length; j++) {
        if (plugins[j]['Group'] == groups[i]) {
          tmp.push(plugins[j]);
        }
      }
      tmp.sort(this.sort_by('Order', false, parseInt));
      let caption = tmp[0]['CurrentCaption'];
      let tooltip = tmp[0]['Tooltip'];
      if (groups[i] == 9999) {
        caption = 'Plugins';
      }
      if (caption != '') {
        result.push({ Group: groups[i], Caption: caption, Tooltip: tooltip, Items: tmp, CurrentIdx: 0 });
      }
    }
    result.sort(this.sort_by('Group', false, parseInt))
    return result;
  }

  public showHiddenMessage() {
    let options = {
      message: ''
    }
    notify(options, "warning", 2000);
  }

  public showMessage(type: string, message?: string, displaytime?: number, width?: number) {
    let param=window.innerWidth/message.length;
    let factor=7.3;
    let msgSize=Math.round(window.innerWidth/factor);
    if(param<factor && type!="Error")
    {
      message=message.substr(0, msgSize) + '...';
      width=window.innerWidth;
    }
    let options:any = {
      position: { at: 'bottom', offset: '0 -50' },
      message: message
    }
    if(width)
    {
      options.width=width;
    }
    if (displaytime) {
      notify(options, type, displaytime);
    } else {
      notify(options, type, 2000);
    }
  }

  public sort_by(field, reverse, primer) {
    let key = primer ? (x) => primer(x[field]) : (x) => x[field];
    reverse = !reverse ? 1 : -1;
    return (a, b) => { return a = key(a), b = key(b), reverse * (+(a > b) - +(b > a)) }
  }

  public groupCardList(cardList: any[], option: any, columnGroup: string): any[] {
    let groups: any[] = [];
    if (cardList != undefined) {
      cardList.forEach(card => {
        if (card[columnGroup]) {
          let cardGroupData: string = card[columnGroup];
          let groupName = '';
          let groupId = '';
          if (cardGroupData.indexOf('#sort#') != -1) {
            let temp = cardGroupData.split('#sort#');
            groupId = temp[0];
            groupName = temp[1].trim();
          } else {
            let dateReg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/g;
            if (cardGroupData.match(dateReg)) {
              let tmp = this.getGroupNameFromDate(card, columnGroup).split('#');
              groupId = tmp[0];
              groupName = tmp[1];
            } else {
              groupId = cardGroupData;
              groupName = cardGroupData;
            }
          }
          let group = groups.find(g => g['id'] == groupId);
          if (group == undefined) {
            groups.push(
              {
                id: groupId,
                name: groupName,
                items: [card],
                show: true,
                sumTotal1: 0,
                sumTotal2: 0,
                sumTotal3: 0
              }
            )
          } else {
            group.sumTotal1 = group.sumTotal1 + card.Sum;
            group.sumTotal2 = group.sumTotal2 + card.Sum2;
            group.sumTotal3 = group.sumTotal3 + card.Sum3;
            group.items.push(card);
          }
        } else {
          let groupId = 'novalue';
          let groupName = 'ListBoardNoGroupText';
          let group = groups.find(g => g['id'] == groupId);
          if (group == undefined) {
            groups.push(
              {
                id: groupId,
                name: groupName,
                items: [card],
                show: true,
                sumTotal1: 0,
                sumTotal2: 0,
                sumTotal3: 0
              }
            )
          } else {
            group.sumTotal1 = group.sumTotal1 + card.Sum;
            group.sumTotal2 = group.sumTotal2 + card.Sum2;
            group.sumTotal3 = group.sumTotal3 + card.Sum3;
            group.items.push(card);
          }
        }
      });
      if (option['Type'] == 'ASC') {
        groups.sort(this.sort_by('id', false, parseInt));
      } else {
        if (option['Type'] == 'DES') {
          groups.sort(this.sort_by('id', true, parseInt));
        }
      }
    }
    return groups;
  }

  public sortGroupCards(groups: any[], option: any): any[] {
    groups.forEach(group => {
      if (option['Type'] == 'ASC') {
        group.items.sort(this.sort_by('OrderData', false, (obj) => {
          if (obj) {
            if (isNaN(obj)) {
              if (obj.match(/[a-z]/i)) {
                return obj;
              } else {
                return new Date(this.fdtp.transform(obj, ''));
              }
            } else {
              return obj / 1;
            }
          }
        }));
      } else {
        group.items.sort(this.sort_by('OrderData', true, (obj) => {
          if (obj) {
            if (isNaN(obj)) {
              if (obj.match(/[a-z]/i)) {
                return obj;
              } else {
                return new Date(this.fdtp.transform(obj, ''));
              }
            } else {
              return obj / 1;
            }
          }
        }));
      }
    });
    return groups;
  }

  public getGroupNameFromDate(card: any, columnGroup: string): string {

    let datePipe = new DatePipe('en-US');
    let itemDate = new Date(this.fdtp.transform(card[columnGroup], ''));
    let currentDate = new Date(this.fdtp.transform(new Date(), ''));
    let cdWeek: number = +this.fdtp.transform(currentDate, 'WeekOfYear');
    let cdMonth: number = +datePipe.transform(currentDate, 'M');
    let cdYear: number = +datePipe.transform(currentDate, 'y');
    let itWeek: number = +this.fdtp.transform(itemDate, 'WeekOfYear');
    let itMonth: number = +datePipe.transform(itemDate, 'M');
    let itYear: number = +datePipe.transform(itemDate, 'y');
    let itMonthDesc: string = datePipe.transform(itemDate, 'MMMM');
    let timeDiff = currentDate.getTime() - itemDate.getTime();
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays == 0) {
      return '0#Today';
    } else {
      if (diffDays > 0) { // is positive if current date is greather than item date
        if (diffDays == 1) {
          return '-1#Yesterday';
        } else {
          if (diffDays > 1 && itWeek == cdWeek && itYear == cdYear) {
            return '-2#ThisWeek';
          } else {
            if (itWeek == cdWeek - 1 && itYear == cdYear) {
              return '-3#PreviousWeek';
            } else {
              if (cdMonth == itMonth && cdYear == itYear) {
                return '-4#CurrentMonth';
              } else {
                if (cdYear == itYear) {
                  return '-5' + itMonth + '#' + itMonthDesc;
                } else {
                  return '-6' + itYear + '#' + itYear;
                }
              }
            }
          }
        }
      } else {
        if (diffDays == -1) {
          return '1#Tomorrow';
        } else {
          if (diffDays < -1 && itWeek == cdWeek && itYear == cdYear) {
            return '2#ThisWeekUp';
          } else {
            if (itWeek == cdWeek + 1 && itYear == cdYear) {
              return '3#NextWeek';
            } else {
              if (cdMonth == itMonth && cdYear == itYear) {
                return '4#CurrentMonthUp';
              } else {
                if (cdYear == itYear) {
                  return '5' + itMonth + '#' + itMonthDesc;
                } else {
                  return '6' + itYear + '#' + itYear;
                }
              }
            }
          }
        }
      }
    }
  }

  public getParamFilters(listSelectedFilters:any){
    let listFilters =[];
    if(listSelectedFilters && listSelectedFilters.length>0)
    {
      listSelectedFilters.forEach(filter => {
        let json={};
        json['GridFilter_ID']=filter['GridFilter_ID'];
        json['GridFilter_Caption']=hiBase64.encode(filter['GridFilter_Caption']);
        json['GridFilter_PictureName']=filter['GridFilter_PictureName'];
        json['GridFilter_Selected']=filter['GridFilter_Selected'];
        json['GridFilter_Values']=filter['GridFilter_Values'];
        json['GridFilter_Type']=filter['GridFilter_Type'];
        json['GridFilter_ORKey']=filter['GridFilter_ORKey'];
        json['GridFilter_SortKey']=filter['GridFilter_SortKey'];
        json['ID']=filter['ID'];
        json['parentFilter']=filter['parentFilter'];
        json['showPrefixCaption']=filter['showPrefixCaption'];
        json['show']=filter['show'];
        json['GridFilter_IsNegative']=filter['GridFilter_IsNegative'];
        listFilters.push(JSON.stringify(json));
      });  
    }
    return listFilters;
  }

  public getListFiltersDecodeBase64(listSelectedFilters:any){
    try{
      if(listSelectedFilters && listSelectedFilters.length>0)
      {
        listSelectedFilters.forEach(filter => {
          try{
            filter['GridFilter_Caption']=hiBase64.decode(filter['GridFilter_Caption']);
          }catch(error){
            console.log(error);
          }
        });
      }
    }catch(error){
      console.log(error);
    }
    return listSelectedFilters;
  }

  public getBrowseGuidFilters(listFilters: any[]): any[] {
    let browseGuidFilters = [];
    if (listFilters != undefined && listFilters.length > 0) {
      listFilters.forEach(filter => {
        if (filter['GridFilter_Selected']) {
          if (filter['GridFilter_ListboardBrowse_GUID'] != '') {
            browseGuidFilters.push(filter['GridFilter_ListboardBrowse_GUID']);
          }
        }
      });
    }
    return browseGuidFilters;
  }


  public toogleGroup(group: any) {
    group.show = !group.show;
  }

  public getInputType(dataType: number, dataFormat: string): string {
    let inputType = 'text';

    switch (dataType) {
      case 1:
        inputType = 'text';
        break;

      case 2:
        inputType = 'number';
        break;

      case 3:
        inputType = 'number';
        break;

      case 4:
        inputType = 'checkbox';
        break;

      case 7:
        inputType = 'date';
        break;

      default:
        inputType = 'object';
        break;
    };


    if (inputType == 'date') {
      if (dataFormat == 'DateShort' || dataFormat == 'DateMedium') {
        inputType = 'datetime-local';
      } else {
        if (dataFormat == 'DateMediumTime' || dataFormat == 'DateShortTime') {
          inputType = 'time';
        }
      }
    }
    return inputType;
  }

  public sleep(ms: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public compareVersions(v1: string, v2: string, options?: any): number {
    if (v1 == undefined) {
      return -1;
    }

    let lexicographical: boolean = options && options.lexicographical;
    let zeroExtend: boolean = options && options.zeroExtend;
    let v1parts: string[] = v1.split('.');
    let v2parts: string[] = v2.split('.');


    if (!v1parts.every((x) => {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }) || !v2parts.every((x) => {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    })) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length)
        v1parts.push("0");
      while (v2parts.length < v1parts.length)
        v2parts.push("0");
    }

    if (!lexicographical) {
      v1parts = v1parts.map(String);
      v2parts = v2parts.map(String);
    }

    for (let i = 0; i < v1parts.length; ++i) {
      if (v2parts.length == i) {
        return 1;
      }
      if (v1parts[i] == v2parts[i]) {
        continue;
      } else if (Number.parseInt(v1parts[i]) > Number.parseInt(v2parts[i])) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length != v2parts.length) {
      return -1;
    }
    return 0;
  };

  public getTextColor(color: number): any {
    let rr = 0;
    let gg = 0;
    let bb = 0;
    let hexnum = '#' + ('000000' + (color & 0xFFFFFF).toString(16)).slice(-6);
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexnum);
    if (result) {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      let brightness = this.brightness(r, g, b);
      if (brightness < 123) {
        rr = 255;
        gg = 255;
        bb = 255;
      }
    }
    return "#" + this.componentToHex(rr) + this.componentToHex(gg) + this.componentToHex(bb);
  }

  private brightness(r: number, g: number, b: number): number {
    return (r * 299 + g * 587 + b * 114) / 1000
  }

  private componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

}
